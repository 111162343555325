import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import {ClientConfig, createHttpClient, createPublicHttpClient} from '@hconnect/apiclient'
import {AuthProvider} from '@hconnect/common/authentication/context'
import {
  authRequestProvider,
  backendSelector,
  loginFlow,
  loginStorage
} from '@hconnect/common/authentication/loginClasses'
import {ApiProvider} from '@hconnect/common/hooks/useApi'
import {HCTheme} from '@hconnect/uikit'
import {overrideHConnectTheme} from '@hconnect/uikit/src/lib2'
import {CircularProgress, CssBaseline} from '@material-ui/core'
import {ThemeProvider} from '@material-ui/styles'
import {
  CssBaseline as CssBaselineMui,
  Theme,
  ThemeProvider as ThemeProviderMui
} from '@mui/material'
import i18next from 'i18next'
import {identity} from 'lodash'
import {SnackbarProvider} from 'notistack'
import React, {Suspense} from 'react'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import {HelmetProvider} from 'react-helmet-async'
import {I18nextProvider} from 'react-i18next'
import {QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter as Router} from 'react-router-dom'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

// TODO: move to separate file
const Api = ({children}: {children: React.ReactNode}) => {
  const clientConfig: ClientConfig = {
    backendSelector,
    authRequestProvider,
    loginStorage,
    loginFlow,

    // FIXME: Discuss removal, should just throw an exception where it happens
    onValidateJsonError: (error, axiosResponse) => {
      console.error('JSON Validation Error:', error, axiosResponse)
    }
  }

  // If you would need to make apis importable, let us discuss how we could restructure. With react-query there should not be a real need for that.
  const secureApi = createHttpClient(clientConfig, {})
  const publicApi = createPublicHttpClient({
    backendSelector
  })

  return (
    <ApiProvider secureApi={secureApi} publicApi={publicApi}>
      {children}
    </ApiProvider>
  )
}

const customBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1280,
    xl: 1920
  }
}

// Note: for unknown reason in unit test overrideDefaultTheme is undefined
// therefore it will be defaulted to identity function
const invitationTheme = (overrideHConnectTheme ?? identity)({
  palette: {
    primary: {
      main: '#29aaff'
    }
  },
  typography: {
    caption: {
      opacity: 1
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        root: ({theme}: {theme: Theme}) => ({
          body: {
            background:
              'linear-gradient(90deg, rgba(0,55,77,1) 0%, rgba(0,99,138,1) 40%,rgba(0,99,138,1) 60%, rgba(0,55,77,1) 100%)'
          },
          'body *::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          'body *::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 4px rgba(0,0,0,0.3)',
            backgroundColor: theme.palette.primary.dark
          },
          'body *::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main
          }
        })
      }
    }
  },
  breakpoints: customBreakpoints
})

export const InvitationProvider = ({children}: {children: React.ReactNode}) => (
  <Suspense fallback={<CircularProgress data-test-id="init-fallback" />}>
    <AuthProvider>
      <Api>
        <HelmetProvider>
          <I18nextProvider i18n={i18next}>
            <ThemeProvider theme={HCTheme}>
              <CssBaseline />
              <ThemeProviderMui theme={invitationTheme}>
                <CssBaselineMui />
                <QueryClientProvider client={queryClient}>
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                    data-test-id="app-snackbar-provider"
                  >
                    <Router>{children}</Router>
                  </SnackbarProvider>
                </QueryClientProvider>
              </ThemeProviderMui>
            </ThemeProvider>
          </I18nextProvider>
        </HelmetProvider>
      </Api>
    </AuthProvider>
  </Suspense>
)
