import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {CreateInvite} from './pages/CreateInvite'
import {Invitations} from './pages/Invitations'
import {ReceiveInvitation} from './pages/ReceiveInvite'
import {InvitationRoutes} from './routes'

export const InvitationRouter = () => {
  return (
    <Switch>
      <Route path={InvitationRoutes.OverviewPage} exact component={Invitations} />
      <Route path={InvitationRoutes.CreateInvitePage} exact component={CreateInvite} />
      <Route path={InvitationRoutes.ReceiveInvite} exact component={ReceiveInvitation} />

      <Redirect to={InvitationRoutes.OverviewPage} />
    </Switch>
  )
}
