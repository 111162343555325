export const InvitationRoutes = {
  LandingPage: '/',
  OverviewPage: '/overview',
  CreateInvitePage: '/createInvite',
  ReceiveInvite: '/receiveInvite/:countryId/:roleRequestId'
}

export interface InvitationUrlPathParameters {
  apiId: string
  appName: string
}
