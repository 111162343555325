import {useBreakPoints} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

import {ReactComponent as HeidelbergMaterialsLogo} from '../../HeidelbergMaterials.svg'
const useStyles = makeStyles()((theme) => ({
  text: {
    paddingLeft: theme.spacing(1.4),
    font: '500 16px/24px "Inter", sans-serif',
    color: theme.palette.grey[700],
    position: 'relative'
  },
  divider: {
    height: 'calc(100% - 1em)',
    margin: '8px 20px',
    borderRight: '1px solid #e7e7e7'
  }
}))

type HeaderLogoProps = {
  fontSize: string
  withBranding?: boolean
}

export const HeaderLogo = ({fontSize, withBranding = true}: HeaderLogoProps) => {
  const {classes} = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  return (
    <Box style={{display: 'flex', height: '3rem', padding: `0 ${isMobile ? 0 : '90px'} 0 0`}}>
      <span className={classes.text} style={{fontSize: fontSize, alignSelf: 'center'}}>
        Invitation
      </span>
      {withBranding && (
        <>
          <div className={classes.divider}></div>
          <Box style={{alignSelf: 'end'}}>
            <HeidelbergMaterialsLogo />
          </Box>
        </>
      )}
    </Box>
  )
}
