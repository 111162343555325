import {useAuth} from '@hconnect/common/authentication/context'
import {useUser} from '@hconnect/common/Invite/hooks/useUser'
import {ReceiveInvite} from '@hconnect/common/Invite/Receive/ReceiveInvitePage'
import React from 'react'

export const ReceiveInvitation: React.FC = () => {
  const {decodedToken} = useAuth()
  const loggedInUserId = decodedToken?.user_id || ''
  const {data} = useUser(loggedInUserId)
  // const dispatch = useDispatch()

  const handleFetchPermissions = () => {
    // TODO
    // dispatch(fetchPermissions(loggedInUserId))
  }

  const handleFetchCustomers = () => {
    // TODO
    // dispatch(fetchCustomers())
  }

  return (
    <ReceiveInvite
      onFetchPermissions={handleFetchPermissions}
      onFetchCustomers={handleFetchCustomers}
      userId={loggedInUserId}
      userCountryId={data?.country || ''}
    />
  )
}
