import {useAuth} from '@hconnect/common/authentication/context'
import {DefaultFooter, EnvAppInfoContainer} from '@hconnect/uikit'
import {Shell} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {LegalLinkType, LegalLinks} from '../components/LegalLinks'
import {ShellHeader} from '../layouts/ShellHeader'
import {InvitationRoutes} from '../routes'

export const DefaultShell = ({children}: {children: React.ReactNode}) => {
  const history = useHistory()
  const {logout, decodedToken} = useAuth()
  const {t} = useTranslation()
  const country = decodedToken?.country_code ? decodedToken?.country_code.toLowerCase() : ''
  const year = new Date().getFullYear()

  const versionAndCopyright: LegalLinkType[] = [
    {
      key: 'copyright',
      title: t('footer.copyright', {year, nationalBrandName: 'HeidelbergMaterials'}),
      endpoint: process.env.REACT_APP_HEIDELBERG_URL,
      isInternal: false
    },
    {
      key: 'version',
      title: <EnvAppInfoContainer />,
      href: '#',
      isInternal: true
    }
  ]

  const termsAndConditions: LegalLinkType[] = [
    {
      key: 'imprint',
      title: t('footer.imprint'),
      endpoint: `${process.env.REACT_APP_LEGAL_URL}/policies/imprint/${country}`,
      isInternal: false
    },
    {
      key: 'termsofuse',
      title: t('footer.termsofuse'),
      endpoint: `${process.env.REACT_APP_LEGAL_URL}/policies/tc/${country}`,
      isInternal: false
    },
    {
      key: 'privacy',
      title: t('footer.privacy'),
      endpoint: `${process.env.REACT_APP_LEGAL_URL}/policies/dpp/${country}`,
      isInternal: false
    }
  ]

  return (
    <Shell
      compact={false}
      isResponsive
      boxed={false}
      showScrollToTop={false}
      stickyFooter={true}
      header={
        <ShellHeader
          onClickHeaderLogo={() => {
            history.push(InvitationRoutes.OverviewPage)
          }}
          user={decodedToken}
          onLogout={() => {
            void logout()
          }}
        />
      }
      footer={
        <DefaultFooter
          links={<LegalLinks links={termsAndConditions} />}
          copyright={<LegalLinks links={versionAndCopyright} />}
        />
      }
    >
      {children}
    </Shell>
  )
}
