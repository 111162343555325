import {AppInsightsAdapter} from '@hconnect/common/logging'
import React from 'react'
import ReactDOM from 'react-dom'

import {App} from './App'
import {initLocalization} from './localization'
import {InvitationProvider} from './providers/InvitationProvider'

initLocalization()
AppInsightsAdapter.init()

ReactDOM.render(
  <React.StrictMode>
    <InvitationProvider>
      <App />
    </InvitationProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
