import {useAuth} from '@hconnect/common/authentication/context'
import {LandingPage} from '@hconnect/uikit/src/lib2'
import {CircularProgress} from '@mui/material'
import {get} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import useTitle from 'react-use/lib/useTitle'

import {HeaderLogo} from './components/commons/HeaderLogo'
import {DefaultShell} from './container/DefaultShell'
import {InvitationRouter} from './InvitationRouter'

export const App = () => {
  const {authState, login, register} = useAuth()
  const {t} = useTranslation()
  useTitle(t('landingPage.title'))

  if (authState === 'loading') {
    return <CircularProgress />
  }

  if (authState !== 'authenticated') {
    if (location.pathname.startsWith('/receiveInvite/')) {
      const countryId: string = get(location.pathname.split('/'), [2], '')
      void register('create-account', countryId)
      return <></>
    }
    return (
      <DefaultShell>
        <LandingPage
          onClickButton={() => {
            void login()
          }}
          loginButtonLabel={t('login.button')}
          footer={<></>}
          bgColor="linear-gradient(90deg, rgba(0,55,77,1) 0%, rgba(0,99,138,1) 40%,rgba(0,99,138,1) 60%, rgba(0,55,77,1) 100%)"
        >
          <HeaderLogo fontSize={'40px'} />
        </LandingPage>
      </DefaultShell>
    )
  }

  return (
    <DefaultShell>
      <InvitationRouter />
    </DefaultShell>
  )
}
