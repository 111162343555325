import {useAuth} from '@hconnect/common/authentication/context'
import {InvitationsOverviewPage} from '@hconnect/common/Invite/Overview/InvitationsOverviewPage'
import React from 'react'

export const Invitations = () => {
  const {decodedToken} = useAuth()
  return (
    <InvitationsOverviewPage
      loggedInUserId={decodedToken?.user_id || ''}
      data-test-id="page-invitation"
    />
  )
}
