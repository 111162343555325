import {DecodedToken} from '@hconnect/apiclient/src'
import {
  AccountDropdown,
  CommonHeader,
  NavItem,
  useBreakPoints,
  useElementSize
} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import {HeaderLogo} from '../components/commons/HeaderLogo'
import {supportedLanguages} from '../localization'
import {InvitationRoutes} from '../routes'

export interface ShellHeaderProps {
  onClickHeaderLogo: () => void
  user: DecodedToken | null
  onLogout: () => void
}

const useStyles = makeStyles()(() => ({
  logoWrapper: {
    cursor: 'pointer'
  },
  logo: {
    fontFamily: 'Work Sans, sans-serif',
    fontWeight: 600,
    textAlign: 'left'
  }
}))

export const ShellHeader = ({user, onLogout, onClickHeaderLogo}: ShellHeaderProps) => {
  const {t, i18n} = useTranslation()
  const {classes} = useStyles()
  const language = i18n.language
  const localesList = supportedLanguages.map((code) => ({
    code,
    name: t(`locales.${code}`)
  }))

  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const AccountMenu = () => {
    if (user) {
      return (
        <AccountDropdown
          actions={{
            logout: onLogout,
            selectLanguage: (lang) => {
              void i18n.changeLanguage(lang)
            }
          }}
          locales={{
            defaultLocale: language,
            locales: localesList,
            localeListLabel: t(`locales.${language}`)
          }}
          logoutButtonText={t('actionBar.logout')}
          profile={{name: user.name, email: user.email}}
        />
      )
    }

    return null
  }

  const headerNavItems: NavItem[] = [
    {
      label: t('navigation.overview'),
      url: InvitationRoutes.OverviewPage,
      dataTestId: 'header-nav-item-invitation-overview'
    },
    {
      label: t('navigation.createInvitation'),
      url: InvitationRoutes.CreateInvitePage,
      dataTestId: 'header-nav-item-create-invitation'
    }
  ]
  const [ref, {width}] = useElementSize()

  return (
    <div ref={ref} style={{width: '100%'}}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        {!isMobile && (
          <Box
            className={classes.logoWrapper}
            data-test-id="shell-header-logo"
            onClick={onClickHeaderLogo}
          >
            <HeaderLogo fontSize={'20px'} withBranding={true} />
          </Box>
        )}
        <Box style={{width: isMobile ? 'auto' : '100%'}}>
          <CommonHeader
            appName="HConnect"
            navItems={headerNavItems}
            disableAppSelect
            parentWidth={width}
          />
        </Box>
        {isMobile && (
          <Box
            className={classes.logoWrapper}
            data-test-id="shell-header-logo"
            onClick={onClickHeaderLogo}
          >
            <HeaderLogo fontSize={'20px'} withBranding={false} />
          </Box>
        )}
        <AccountMenu />
      </Box>
    </div>
  )
}
